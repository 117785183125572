import Link from 'next/link';
import React, {useEffect, useMemo, useState} from 'react';
import useAuth from '@/components/auth/hooks/useAuth';
import LoginPage from '@/components/auth/users/login-page';
import AppModal from '@/components/modal';
import {FiLogOut, FiSearch, FiShoppingCart, FiUser} from "react-icons/fi";
import {MdClose} from "react-icons/md";
import useCart from "@/components/e-cart/hooks/useCart";


type NavToolItemProps = {
  key: string;
  title: string;
  path?: string;
  count?: number | string;
  children?: React.ReactNode;
  Icon?: any;
};

interface NavToolsProps {
  items?: NavToolItemProps[];
}

const NavTools: React.FC<NavToolsProps> = (props) => {
  const {isAuthenticated} = useAuth();
  const {cart} = useCart()

  const items = useMemo(() => [
    {
      key: 'login',
      title: 'Login',
      Icon: isAuthenticated ? FiLogOut : FiUser,
      path: isAuthenticated ? '/session/logout' : '/login',
      children: !isAuthenticated ? <LoginPage/> : null
    },
    {
      key: 'cart',
      title: 'Cart',
      Icon: FiShoppingCart,
      count: cart?.length || 0,
      path: '/cart'
    },
    {
      key: 'search',
      title: 'Search',
      Icon: FiSearch,
      path: '/p/search'
    }
  ], [isAuthenticated, cart]);

  return (
    <nav>
      <ul className="flex flex-row items-center justify-center gap-2">
        {items.map((item, key) => (
          <NavTool key={key} item={item}/>
        ))}
      </ul>
    </nav>
  );
};

const NavTool = ({item}: { item: NavToolItemProps }) => {
  const [isVisible, setIsVisible] = useState(false);
  const open = () => setIsVisible(true);
  const close = () => setIsVisible(false);
  return (
    <li>
      {item.children ? (
        <button type="button" onClick={open} className="w-[50px] h-[50px] flex flex-row items-center justify-center">
          {item.Icon ? <item.Icon size={23}/> : <span>{item.title}</span>}
          {Boolean(item.count) && <span className={"w-[15px] h-[15px] relative left-0 bg-dark text-white rounded"}>{item.count}</span>}
        </button>
      ) : (
        <Link href={item.path || '#'} className="w-[50px] h-[50px] flex flex-row items-center justify-center relative">
          {item.Icon ? <item.Icon size={23}/> : <span>{item.title}</span>}
          {Boolean(item.count) && <span className={"w-[15px] h-[15px] text-[10px] top-6 left-6 p-1 flex flex-row items-center justify-center absolute bottom-0 bg-dark text-white font-bold rounded-full"}>{item.count}</span>}
        </Link>
      )}
      {item.children && isVisible && (
        <AppModal className="" IconClose={<MdClose size={23}/>} visible={isVisible} show={open} close={close}>
          {item.children}
        </AppModal>
      )}
    </li>
  );
};

export default NavTools;
