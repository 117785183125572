'use client';

import BrandLogo from "@/components/brand-logo";
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {RiMenu4Line as IconMenu} from "react-icons/ri";
import React, {useState} from 'react';


import NavTools from '../nav-tools';
import Link from "next/link";

interface MobileNavProps {
}

const MobileNav: React.FC<MobileNavProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const openMenu = () => setIsVisible(true);
  const closeMenu = () => setIsVisible(false);

  return (
    <>
      <nav className="md:flex flex items-center justify-between fixed top-0 left-0 z-40 w-full px-2">
        <div className="md:flex flex items-center justify-start gap-5">
          <button type="button" onClick={openMenu} className="w-[50px] h-[50px] flex items-center justify-center">
            <IconMenu size={23}/>
          </button>
          <BrandLogo/>
        </div>
        <div>
          <NavTools/>
        </div>
      </nav>
      {isVisible && (
        <div className="bg-white text-dark fixed top-0 left-0 z-50 h-full flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className={"px-5"}><BrandLogo/></div>
            <button type="button" onClick={closeMenu} className="w-[50px] h-[50px] flex items-center justify-center">
              <Icon icon={faClose} size="xl" fixedWidth={true}/>
            </button>
          </div>
          <div className="flex-1 overflow-auto min-w-[400px] max-w-[500px]">
            <nav className={"px-5 w-full"}>
              <ul className={"flex flex-col gap-5 size-1 w-full uppercase"}>
                <li><Link href={"/"}>Inicio</Link></li>
                <li><Link href={"/p/lines/Baby Shower"}>Baby Shower</Link></li>
                <li><Link href={"/p/lines/Bebe"}>Bebe</Link></li>
                <li><Link href={"/p/lines/mini"}>Mini</Link></li>
                <li><Link href={"/p/lines/teens"}>Teens</Link></li>
                <li><Link href={"/p/new-in"}>New In</Link></li>
                <li><Link href={"/p/sales"}>Sales</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
