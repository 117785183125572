import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0_@babel+core@7.26.0_react-dom@19.0.0-rc-65a56d0e-20241020_react@19.0.0-rc-65a56d0e_gsnf4zb6jpbt5guvfc3fypjg54/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0_@babel+core@7.26.0_react-dom@19.0.0-rc-65a56d0e-20241020_react@19.0.0-rc-65a56d0e_gsnf4zb6jpbt5guvfc3fypjg54/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.0_@babel+core@7.26.0_react-dom@19.0.0-rc-65a56d0e-20241020_react@19.0.0-rc-65a56d0e_gsnf4zb6jpbt5guvfc3fypjg54/node_modules/next/font/google/target.css?{\"path\":\"src/bundles/core/components/client/layout/index.tsx\",\"import\":\"Quicksand\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"quicksand\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/bundles/core/components/client/layout/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/bundles/core/components/client/mobile-nav/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/bundles/core/components/client/socials/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartProvider"] */ "/app/src/components/e-cart/provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/errors/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/errors/error.tsx");
