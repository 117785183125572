import React from 'react';

import { PreferenceItem } from '../mercado-pago/api/preference-response';

export interface CartItem {
  product: any;
  quantity: number;
  pref?: PreferenceItem;
}

export interface DiscountItem {
  id: string;
  title: string;
  amount: number;
}

interface CartContextValue {
  id: string | null;
  loading: boolean;
  mercadopago?: { public_key: string };
  cart: CartItem[];
  discounts: DiscountItem[];
  shipping: { cost: number; address: string } | null;
  client: any;
  totaldiscount: number;
  subtotal: number;
  canCheckout?: boolean;
  addDiscount: (item: DiscountItem) => void;
  addToCart: (item: CartItem) => void;
  updateOrder: (item: CartItem) => void;
  removeFromCart: (item: CartItem) => void;
  checkoutCart: (callback?: () => void) => Promise<void>;
  persistCart: (cart?: any, discount?: any, callback?: () => void) => Promise<void>;
  persistClient: (data: any, endpoint: string) => Promise<void>;
  persistShipping: (data: any, endpoint: string) => Promise<void>;
  clearClient: () => void;
  clearShipping: () => void;
  clearCart: () => void;
  itemExists: (item: CartItem) => boolean;
  fprice: (price: number) => string;
}

const initialCartContextValue: CartContextValue = {
  id: null,
  loading: false,
  cart: [],
  discounts: [],
  shipping: null,
  client: null,
  canCheckout: false,
  mercadopago: { public_key: '' },
  totaldiscount: 0,
  subtotal: 0,
  addDiscount: function(item: DiscountItem): void {
    throw new Error('CartProvider not found');
  },
  addToCart: function(item: CartItem): void {
    throw new Error('CartProvider not found');
  },
  updateOrder: function(item: CartItem): void {
    throw new Error('CartProvider not found');
  },
  removeFromCart: function(item: CartItem): void {
    throw new Error('CartProvider not found');
  },
  checkoutCart: function(): Promise<void> {
    throw new Error('CartProvider not found');
  },
  persistCart: function(): Promise<void> {
    throw new Error('CartProvider not found');
  },
  persistClient: function(data: any, endpoint: string): Promise<void> {
    throw new Error('CartProvider not found');
  },
  persistShipping: function(data: any, endpoint: string): Promise<void> {
    throw new Error('CartProvider not found');
  },
  itemExists: function(item: CartItem): boolean {
    return false;
  },
  clearCart: function(): void {
    throw new Error('CartProvider not found');
  },
  clearClient: function(): void {
    throw new Error('CartProvider not found');
  },
  clearShipping: function(): void {
    throw new Error('CartProvider not found');
  },
  fprice: function(price: number): string {
    return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(price);
  }
};

const CartContext = React.createContext<CartContextValue>(initialCartContextValue);

export const CartConsumer = CartContext.Consumer;

export default CartContext;
