'use client';

import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC, ReactNode } from 'react';

import BgSlider, { BgSliderItem } from '@/components/bg-slider';

// bg-transparent text-dark bg-no-repeat bg-gradient-to-b from-gray-disabled via-transparent to-transparent bg-[length:100%_40%] bg-[image:var(--image-url)] bg-cover bg-left-top

const AppHeader: FC<{ isHome?: boolean; banners?: string[]; children: ReactNode }> = ({ isHome, children, banners }) => {
  // const [bannerImg, setBannerImg] = useState(banners?.at(0));

  return (
    <header
      // style={{ '--image-url': `url(${bannerImg})` }}
      className={classNames({ 'h- bg-cover bg-left-top bg-[image:var(--image-url)] ': isHome }, 'p-0 ')}
    >
      {isHome && (
        <BgSlider className="h-[50vh] overflow-hidden">
          {banners?.map((image, index) => (
            <BgSliderItem key={index} className="absolute inset-0 ">
              <Link href={'/'} className={'w-full h-full relative object-fit'}>
                <Image src={image} width={1080} height={768} alt={'banner'} className="object-cover w-full" />
                <div className="absolute top-1/2 left-1/2 ">
                  <div className="bg-light px-5 h-[30px] flex flex-row justify-center items-center">Shop Now</div>
                </div>
              </Link>
            </BgSliderItem>
          ))}
        </BgSlider>
      )}
      {children}
    </header>
  );
};

export default AppHeader;
