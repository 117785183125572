"use client";

import React from "react";

export interface ErrorPageProps {
  error?: Error & { digest?: string };
  reset?: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  return (
    <div>
      <h1>Error Page</h1>
      <p>Oops! Something went wrong.</p>
    </div>
  );
};

export default ErrorPage;
