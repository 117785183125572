import classNames from 'classnames';
import React, { FC } from 'react';

type AppModalProps = {
  visible: boolean;
  show: () => void;
  close: () => void;
  IconClose?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

const AppModal: FC<AppModalProps> = ({ visible, className, show, close, IconClose, children }) => {
  return (
    <div>
      {visible && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-light opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              ​
            </span>
            <div
              className={classNames(
                'relative inline-block align-bottom bg-white text-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full',
              )}
            >
              <div className="flex flex-row-reverse absolute w-full">
                <button type="button" className="mt-3 w-full inline-flex justify-center px-4 py-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={close}>
                  {IconClose ?? 'x'}
                </button>
              </div>
              <div className={classNames(className, 'px-4 pt-5 pb-4 sm:p-6 sm:pb-4')}>{children}</div>
              {/* <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={close}>
                                    Cerrar
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppModal;
