import React, {FC, ReactNode, useEffect, useRef} from "react";
import {gsap} from "gsap";

export type BgSliderProps = {
  children: React.ReactNode;
  className?: string;
};


export const BgSliderItem: FC<{ children: ReactNode, className?:string }> = ({children, className}) => {
  return (
    <div className={`w-full h-full opacity-0 ${className}`}>
      {children}
    </div>
  );
}

const BgSlider: FC<BgSliderProps> = ({children, className}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const currentIndex = useRef(0);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const slides = Array.from(slider.children) as HTMLElement[];
    const totalSlides = slides.length;

    const showSlide = (index: number) => {
      gsap.to(slides, {autoAlpha: 0, duration: 1});
      gsap.to(slides[index], {autoAlpha: 1, duration: 1});
    };

    const nextSlide = () => {
      currentIndex.current = (currentIndex.current + 1) % totalSlides;
      showSlide(currentIndex.current);
    };

    showSlide(currentIndex.current);
    const interval = setInterval(nextSlide, 10000);

    return () => clearInterval(interval);
  }, [children]);

  return (
    <div ref={sliderRef} className={`relative w-full ${className}`}>
      {children}
    </div>
  );
};

export default BgSlider;
