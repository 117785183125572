'use client';

import { add, multiply, subtract } from 'mathjs';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ShopService } from '@/bundles/core/services/shop';
import LOG from '@/lib/logger';

import { PreferenceItem } from '../mercado-pago/api/preference-response';
import CartContext, { CartItem, DiscountItem } from './context';

interface CartProviderProps {
  children: ReactNode;
  mercadopago?: {
    public_key: string;
  };
}

const API_PROXY_URL = process.env.API_PROXY_URL || 'http://localhost:8081';

export const CartProvider: React.FC<CartProviderProps> = ({ children, mercadopago }) => {
  const [loading, setLoading] = useState(false);
  const [prefId, setPrefId] = useState<string | null>(null);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [discounts, setDiscounts] = useState<DiscountItem[]>([]);
  const [client, setClient] = useState<any>(null);
  const [shipping, setShipping] = useState<{ cost: number; address: string } | null>(null);
  const [shopService] = useState(new ShopService(API_PROXY_URL));

  const addToCart = async (order: CartItem) => {
    if (!order.quantity || order.quantity < 1) return LOG.debug({ message: 'Invalid quantity of article' });
    if (!order.product) return LOG.debug({ message: 'Invalid product' });

    order.pref = {
      id: order?.product?.id,
      title: order?.product?.name,
      description: order?.product?.description,
      quantity: order?.quantity,
      unit_price: shopService.getUserPrice(order?.product, {}),
      currency_id: 'ARS',
      category_id: '',
    };

    const idx = itemExists(order);

    if (!idx) {
      setCart((prev) => [...prev, order]);
      await persistCart([...cart, order], []);
      LOG.debug({ message: 'Article added to cart', order });
    }
  };

  const subtotal = useMemo(
    () =>
      cart.reduce((acc, item) => {
        if (!item.quantity || item.quantity < 1) return acc;
        return add(acc, multiply(item.quantity, item.pref?.unit_price ?? 0));
      }, 0),
    [cart],
  );

  const totaldiscount = useMemo(() => discounts.reduce((acc, item) => add(acc, item.amount), 0), [discounts]);

  const canCheckout = useMemo(() => {
    let cancheckout = cart.length > 0 && subtotal > 0;
    if (!client) cancheckout = false;
    if (!shipping) cancheckout = false;
    return cancheckout;
  }, [shipping, client, cart, totaldiscount, subtotal]);

  const updateOrder = (order: CartItem) => {};

  const removeFromCart = async (order: CartItem) => {
    const idx = findIndex(order);
    if (idx > -1) {
      setCart((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1)]);
      await persistCart([...cart.slice(0, idx), ...cart.slice(idx + 1)], []);
    }
  };

  const findIndex = (order: CartItem) => cart.findIndex((item) => item?.product?.id === order?.product?.id);

  const itemExists = (order: CartItem) => findIndex(order) > -1;

  const checkoutCart = useCallback(
    async (callback = () => {}) => {
      try {
        if (prefId) {
          callback?.();
          return;
        }
        setLoading(true);
        const item: PreferenceItem = {
          id: uuidv4(),
          category_id: '',
          currency_id: 'ARS',
          description: '',
          title: 'Compras Crayon Ropa de Niño',
          quantity: 1,
          unit_price: 0,
        };
        item.unit_price = subtract(subtotal, totaldiscount);
        const pref = await fetch('/api/mercado-pago', {
          method: 'POST',
          body: JSON.stringify({ items: [item] }),
          headers: { 'Content-Type': 'application/json' },
        });
        if (!pref.ok) throw new Error('Error MercadoPago al crear la preferencia');
        const { id } = await pref.json();
        setPrefId(id);
        callback?.();
      } catch (error) {
        LOG.warn({ message: (error as Error).message, stack: (error as Error).stack });
      } finally {
        setLoading(false);
      }
    },
    [subtotal, cart, discounts, prefId],
  );

  const persistCart = async (cart: unknown = [], discounts: unknown = [], callback?: any) => {
    try {
      localStorage.setItem('cart', JSON.stringify(cart));
      localStorage.setItem('discounts', JSON.stringify(discounts));
      callback?.();
    } catch (error) {
      LOG.warn({ message: (error as Error).message, stack: (error as Error).stack });
    }
  };

  const persistClient = async (data: any, endpoint: string) => {
    try {
      let user = (await (await fetch(`${endpoint}?filters=[email:${data?.email}]`)).json())?.data?.at(0);
      if (!user)
        user = (
          await (
            await fetch(`${endpoint}`, {
              method: 'POST',
              body: JSON.stringify({ ...data, password: data?.doc }),
              headers: { 'Content-Type': 'application/json' },
            })
          ).json()
        ).data;
      setClient(user);
      localStorage.setItem('client', JSON.stringify(user));
    } catch (error) {
      LOG.warn({ message: (error as Error).message, stack: (error as Error).stack });
    }
  };

  const persistShipping = async (data: any, endpoint: string) => {
    let error: any = null;
    try {
      delete data?.id;

      let address = (
        await (
          await fetch(`${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
          })
        ).json()
      ).data;
      if (!address?.id) {
        alert('Direccion invalida!!');
        error = new Error(address);
      }
      let cost = address.group ? 0 : 1000;
      setShipping({ cost, address });
      localStorage.setItem('shipping', JSON.stringify({ cost, address }));
    } catch (err) {
      LOG.warn({ message: (err as Error).message, stack: (err as Error).stack });
      error = err;
    }

    if (error) throw new Error(error);
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
  };

  const clearClient = () => {
    setClient(null);
    localStorage.removeItem('client');
  };

  const clearShipping = () => {
    setShipping(null);
    localStorage.removeItem('shipping');
  };

  const addDiscount = (item: DiscountItem) => {
    const idx = discounts.findIndex((discount) => discount.id === item.id);
    if (idx === -1) {
      setDiscounts((prev) => [...prev, item]);
    }
  };

  const fprice = (price: number) => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(price);

  const hydrateCart = useCallback(async () => {
    try {
      let cart = localStorage.getItem('cart');
      let discounts = localStorage.getItem('discounts');
      if (cart) setCart(JSON.parse(cart));
      if (discounts) setDiscounts(JSON.parse(discounts));
    } catch (error) {
      LOG.warn({ error: (error as Error).name, stack: (error as Error).stack });
    }
  }, []);

  const hydrateClient = useCallback(async () => {
    let client = localStorage.getItem('client');
    if (client) setClient(JSON.parse(client));
  }, []);

  const hydrateShipping = useCallback(async () => {
    let shipping = localStorage.getItem('shipping');
    if (shipping) setShipping(JSON.parse(shipping));
  }, []);

  useEffect(() => {
    hydrateCart().then();
    hydrateClient().then();
    hydrateShipping().then();
  }, []);

  return (
    <CartContext.Provider
      value={{
        id: prefId,
        mercadopago,
        cart,
        discounts,
        subtotal,
        totaldiscount,
        loading,
        shipping,
        client,
        canCheckout,
        fprice,
        clearCart,
        addDiscount,
        addToCart,
        updateOrder,
        persistCart,
        removeFromCart,
        checkoutCart,
        itemExists,
        persistClient,
        persistShipping,
        clearClient,
        clearShipping,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
